import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Input, Select, TextArea, Button, Checkbox, Radio } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import Layout from '../components/Layout';
import { navigate } from 'gatsby';

const FIELDS = {
  firstName: 'firstName',
  lastName: 'lastName',
  phoneNumber: 'phoneNumber',
  email: 'email',
  reason: 'reason',
  team: 'team',
  gdprConsent: 'gdprConsent',
  message: 'message',
};
const TEAM = {
  kids: 'kids',
  worship: 'worship',
  production: 'production',
  future: 'future',
  connect: 'connect',
  media: 'media',
};

type State = 'submitting' | 'default';

const PreRenderHiddenForms = () => (
  <div className="hidden-forms">
    {Object.keys(TEAM).map((team) => (
      <Form inverted data-netlify="true" name={team} method="POST" action="/contact-success">
        <input type="hidden" name="form-name" value={team} />
        <div className="top">
          <Input name="firstName" />
          <Input name="lastName" />
          <Input name="phoneNumber" />
          <Input name="email" />
        </div>
        <div className="bottom">
          <Form.Group grouped>
            <Form.Field>
              <Radio name="team" />
            </Form.Field>
            <Form.Field>
              <Radio name="team" />
            </Form.Field>
            <Form.Field>
              <Radio name="team" />
            </Form.Field>
            <Form.Field>
              <Radio name="team" />
            </Form.Field>
            <Form.Field>
              <Radio name="team" />
            </Form.Field>
          </Form.Group>
          <Checkbox name="gdprConsent" />
        </div>
      </Form>
    ))}
  </div>
);

const JoinTeam = () => {
  const {
    watch,
    register,
    getValues,
    setValue,
    trigger,
    errors,
  } = useForm();
  const [state, setState] = useState<State>('default');

  const teamValue = watch(FIELDS.team, null);
  const gdprConsentValue = watch(FIELDS.gdprConsent, null);
  const formName = teamValue || 'contact';

  useEffect(() => {
    Object.keys(FIELDS).forEach((key) => register({ name: key }, { required: true }));
  }, []);

  const onChange = async (_event, { name, value }) => {
    if ([FIELDS.gdprConsent].includes(name)) {
      setValue(name, !getValues()[name]);
    } else {
      setValue(name, value);
    }

    if (name === FIELDS.reason && value !== 'joining_team') {
      setValue(FIELDS.team, null);
    }

    await trigger(name);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const payload: any = {};
    const formValues = getValues();

    Object.keys(FIELDS).forEach((key) => payload[key] = formValues[key]);

    try {
      const form = e.currentTarget;
      console.log(e.currentTarget);
      const body = `form-name=${form.name}&${Object.keys(payload).map(key => `${key}=${payload[key]}`).join('&')}`;
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body,
      };

      console.log(options);
      fetch('/', options)
        .then(function (response) {
          navigate(form.action);
        })
        .catch(function (error) {
          alert('Failed to submit your form. Please check your network connection and try again');
          setState('default');
        });
    } catch (error) {
      alert('Failed to submit your form. Please check your network connection and try again');
      setState('default');
    }
  };


  return (
    <Layout className="join-team">
      <article id="main">
        <header>
          <h1>
            Join A Team
          </h1>
        </header>
        <section>
          <p>
            Begin making a difference today by using your gifts and skills.
          </p>
          <Form inverted className="form" name={formName} method="POST" action="/contact-success" onSubmit={onSubmit}>
            <input type="hidden" name="form-name" value={formName} />
            <div className="top">
              <Form.Group grouped>
                <Form.Field>
                  <Radio
                    name="team"
                    label="Expectation Kids - Shape the next generation."
                    checked={teamValue === 'kids'}
                    onChange={onChange}
                    value={TEAM.kids}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    name="team"
                    label="Expectation Worship - Point people to Jesus through music."
                    checked={teamValue === 'worship'}
                    onChange={onChange}
                    value={TEAM.worship}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    name="team"
                    label="Expectation Production - Create a dynamic atmosphere through audio & visuals."
                    checked={teamValue === 'production'}
                    onChange={onChange}
                    value={TEAM.production}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    name="team"
                    label="Expectation Future - Inspire young people to live for Jesus."
                    checked={teamValue === 'future'}
                    onChange={onChange}
                    value={TEAM.future}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    name="team"
                    label="Expectation Connect - Make church feel like home."
                    checked={teamValue === 'connect'}
                    onChange={onChange}
                    value={TEAM.connect}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    name="team"
                    label="Expectation Media - Communicate the vision through photography, video & social media"
                    checked={teamValue === 'media'}
                    onChange={onChange}
                    value={TEAM.media}
                  />
                </Form.Field>
              </Form.Group>
              <Input
                error={Boolean(errors[FIELDS.firstName])}
                fluid
                onChange={onChange}
                placeholder="First Name"
                name="firstName"
                type="text"
                required
              />
              <Input
                error={Boolean(errors[FIELDS.lastName])}
                fluid
                onChange={onChange}
                placeholder="Last Name"
                name="lastName"
                type="text"
                required
              />
              <Input
                error={Boolean(errors[FIELDS.phoneNumber])}
                fluid
                onChange={onChange}
                placeholder="Phone Number"
                name="phoneNumber"
                type="tel"
                required
              />
              <Input
                error={Boolean(errors[FIELDS.email])}
                fluid
                onChange={onChange}
                placeholder="Email"
                name="email"
                type="email"
                required
              />
              <TextArea
                error={Boolean(errors[FIELDS.message])}
                fluid
                onChange={onChange}
                className="message"
                placeholder="Tell us more (optional)"
                name="message"
                type="text"
                required
              />
            </div>
            <div className="bottom">
              <Checkbox
                required
                checked={gdprConsentValue}
                onChange={onChange}
                name="gdprConsent"
                label="I consent to Expectation Church having the info provided in this form."
              />
              <Button
                inverted
                basic
                disabled={!gdprConsentValue || state === 'submitting'}
                type="submit"
                loading={state === 'submitting'}
              >
                Submit
              </Button>
            </div>
          </Form>
        </section>
        <PreRenderHiddenForms />
      </article>
    </Layout>
  );
};

export default JoinTeam;
